<template>
	<div>
		<fieldset class="search-box">
			<legend>查询条件</legend>
			<el-input size="small" style="width: 300px;margin-right: 20px;" placeholder="请输入商户名" v-model="input1">
				<template #prepend>商户名</template>
			</el-input>
			<el-button size="small">搜索</el-button>
			<el-button @click="openWin(1)" type="primary" size="small">添加商户</el-button>
		</fieldset>

		<div ref="tableBox" style="margin-top: 10px;flex-grow: 1; background-color: white;padding-bottom: 20px;">
			<el-table size="small" header-cell-class-name="tableHeader" :data="list" border style="width: 100%;">
				<el-table-column fixed prop="title" label="商户名" width="200">
				</el-table-column>
				<el-table-column fixed="right" label="操作">
					<template #default="scope">
						<el-button @click="openWin(2,scope.row)" type="text" size="small">查看</el-button>
						<el-button @click="deletItem(scope.row)" type="text" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="margin-top: 20px; text-align: right;">
				<el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
			</div>
		</div>

		<el-dialog :title="diaTitle" v-model="addDialog" width="70%" top="2vh">
			<el-card>
				<el-form size="small" label-position="left" label-width="120px"  v-model="addItem">
					<el-form-item label="商户名">
						<el-input v-model="addItem.title"></el-input>
					</el-form-item>
					<el-form-item label="商户号">
						<el-input v-model="addItem.mchId"></el-input>
					</el-form-item>
					<el-form-item label="证书序列号">
						<el-input v-model="addItem.serialNo"></el-input>
					</el-form-item>
					<el-form-item label="apiV3">
						<el-input v-model="addItem.apiV3"></el-input>
					</el-form-item>
					<el-form-item label="支付凭证(cert)">
						<el-input type="textarea" :rows="3" v-model="addItem.cert"></el-input>
					</el-form-item>
					<el-form-item label="支付密钥(key)">
						<el-input type="textarea" :rows="3" v-model="addItem.payKey"></el-input>
					</el-form-item>
				</el-form>
			</el-card>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button v-show="showAddBtn" @click="add" type="primary">添加</el-button>
					<el-button v-show="!showAddBtn" @click="update" type="primary">更新</el-button>
				</span>
			</template>
		</el-dialog>
		
	</div>

</template>

<script>
	import {
		addWxMerchant,
		getWxMerchantList,
		getMerchantById,
		deleteWxMerchantItem,
		updateWxMerchantItem
	} from '../../api/merchant.js'
	import {
		ElNotification,
	} from 'element-plus'
	export default {
		data() {
			return {
				addDialog: false,
				addItem: {},
				list:[],
				diaTitle:"",
				showAddBtn:true
			}
		},

		mounted() {
			this.getList()
		},

		methods: {
			openWin(type,data){
				this.addItem = {}
				switch(type){
					case 1:
						this.diaTitle = "添加商户"
						this.showAddBtn = true
					break
					case 2:
						var that = this
						this.diaTitle = "更新商户"
						this.showAddBtn = false
						getMerchantById(data.id).then(res=>{
							that.addItem = res.data.data
						})
						
				}
				this.addDialog = true
			},
			
			update:function(){
				var that = this
				updateWxMerchantItem(that.addItem).then(()=>{
					ElNotification({
						title: '成功',
						message: '添加成功！',
						type: 'success',
					})
					that.getList()
					that.addDialog = false
				})
			},
			
			add:function(){
				var that = this
				addWxMerchant(this.addItem).then(res=>{
					let data = res.data.data
					if(data != 0){
						ElNotification({
							title: '成功',
							message: '添加成功！',
							type: 'success',
						})
						that.getList()
						that.addDialog = false
					}
				}).catch(()=>{
					ElNotification({
						title: '失败',
						message: '服务器错误！',
						type: 'error',
					})
				})
			},
			
			getList:function(){
				var that = this
				getWxMerchantList(0).then(res=>{
					let data = res.data.data
					that.list = data
				})
			},
			
			deletItem:function(data){
				var that = this
				deleteWxMerchantItem(data.id).then(()=>{
					ElNotification({
						title: '成功',
						message: '删除成功！',
						type: 'success',
					})
					that.getList()
				})
			}
			
			
		}
	}
</script>

<style scoped="scoped">
.search-box {
		margin: 0;
		border: 1px solid #e6e6e6;
		padding: 10px 20px 20px 20px;
		color: #6b6b6b;
	}
</style>
