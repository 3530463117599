import request from '../util/requset.js'

export function add(data){
	return request.post("/merchant/add",data)
}

export function list(start,num){
	return request.get("/merchant/getMerchantList?start="+start+"&num="+num)
}

export function payTest(clientId){
	return request.get("/merchant/payTest?clientId="+clientId)
}

export function update(data){
	return request.post("/merchant/updateMerchant",data)
}

export function wxBaoJingTest(clientId,openId){
	return request.get("/merchant/wxTest?clientId="+clientId+"&openId="+openId)
}

export function phoneTest(phone,clientId){
	return request.get("/merchant/phoneTest?phone="+phone+"&clientId="+clientId)
}

export function phoneTestTx(phone,clientId){
	return request.get("/merchant/phoneTestTx?phone="+phone+"&clientId="+clientId)
}

export function smsTest(phone,clientId){
	return request.get("/merchant/smsTest?phone="+phone+"&clientId="+clientId)
}

/**
 * 获取支付商户
 */
export function getWxMerchantList(start){
	return request.get("/merchant/getWxMerchantList?start="+start)
}

/**
 * 删除商户
 * @param {Object} id
 */
export function deleteWxMerchantItem(id){
	return request.delete("/merchant/deleteWxMerchant?id="+id)
}

/**
 * 更新商户
 * @param {Object} data
 */
export function updateWxMerchantItem(data){
	return request.post("/merchant/updateWxMerchant",data)
}

/**
 * 添加商户
 * @param {Object} data
 */
export function addWxMerchant(data){
	return request.post("/merchant/addWxMerchant",data)
}

export function getMerchantById(id){
	return request.get("/merchant/getMerchantById?id="+id)
}